////////////////////////////////////////////////
// TODO: cleanup and remove with VFDP-522
//
// *********** CAUTION ***********
// removing these classes could result
// in custom content not displaying
// correctly
////////////////////////////////////////////////
@mixin foundation-4-float-classes {
  // Miscellaneous useful HTML classes
  .left {
    float: left !important; // sass-lint:disable-line no-important
  }

  .right {
    float: right !important; // sass-lint:disable-line no-important
  }

  .small-text-left {
    text-align: left;
  }

  .small-text-right {
    text-align: right;
  }

  .small-text-center {
    text-align: center;
  }

  .small-text-justify {
    text-align: justify;
  }
}
